import "./styles.scss";
import React, { useEffect, useState } from "react";
import GanttContainer from "./GanttContainer";
import * as getEstimateActionCreators from '../../../store/estimate/getEstimate';
import * as getResourcesActionCreators from '../../../store/resources/getResources'
import * as getPhasesActionCreators from '../../../store/phases/getPhases';
import { compose, bindActionCreators } from "redux";
import { connect } from 'react-redux';

// import model from "./sources/models/data";

import PhaseCalculation from "../../small-components/Phase/phaseCalculationUtil";
import { withRouter } from "react-router-dom";
import fire from "../../../config/firebase";
let temp = {};
const GanttContainerApp = (props) => {
  const { calculations } = props;
  const clone = (obj) => {
    return obj ? JSON.parse(JSON.stringify(obj)) : obj;
  }
  const refreshCallBack = async () => {
    const { getEstimateAsync } = props.getEstimateAction;
    await getEstimateAsync(getQuery());
  };
  const { estimateState, resources, phases, refreshSignal } = props;
  const getQuery = () => {
    const companyId = localStorage.getItem('companyId');
    const arr = props.location.pathname.split('/');
    const estimateKey = arr[arr.length - 1];
    const dataType = props.match.path.includes("estimates")
      ? "estimates"
      : "templates";
    return { dataType, estimateKey, companyId, shared: props.shared }
  }
  const { companyId, estimateKey, dataType } = getQuery();
  const { updateCnt = 0 } = props;

  const emptyData = { data: [], liks: [] };
  const getDataFromStore = async () => {
    const { getResourcesAsync } = props.getResourcesAction;
    await getResourcesAsync();
    const { getPhasesAsync } = props.getPhasesAction;
    await getPhasesAsync();
  };
  const [estimate, setEstimate] = useState(null);
  // const [initGantt, setInitGantt] = useState(null);

  const [currentRefreshSignal, setCurrentRefreshSignal] = useState(0);
  useEffect(() => {
    if ((estimate === null && estimateState && estimateState.phases) || (refreshSignal !== 0 && refreshSignal !== currentRefreshSignal)) {
      setEstimate(clone(estimateState));
      setCurrentRefreshSignal(refreshSignal);
    }
    setNewHeight(getHeight())
  }, [estimateState, refreshSignal]);

  useEffect(() => {
    updateBillingSetting();
  }, [updateCnt]);

  const updateBillingSetting = () => {
    const timebasedRef = fire.database().ref(companyId).child(dataType).child(estimateKey);
    timebasedRef.once("value").then((snapshot) => {
      const item = snapshot.val();
      if (item && estimate) {
        const isBillable = item.billableSwitch !== estimate.billableSwitch;
        const profitMarginChanged = item.profitMargin !== estimate.profitMargin;
        const targetMarginChanged = item.targetMargin !== estimate.targetMargin;
        const hourlyRateChanged = item.definedhourlyRate !== estimate.definedhourlyRate;

        if (isBillable || profitMarginChanged || targetMarginChanged || hourlyRateChanged) {
          const user = fire.auth().currentUser.uid;
          const newEstimateData = Object.assign(item, { lastEditor: user });
          timebasedRef.update(newEstimateData);
          setEstimate(newEstimateData);
          getDataFromStore();
        }
      }
    })
  }

  const onTasksChanged = async (data, role, data3) => {
    // console.log(data, role);

    // const { getEstimateAsync } = props.getEstimateAction;
    // const tempest = await getEstimateAsync(getQuery());

    const estimateTemp = clone(estimate);

    const timebasedRef = fire.database().ref(companyId).child(dataType).child(estimateKey);
    const estimatePrevious = clone(estimate);
    if (estimateTemp) {
      estimateTemp.phases = [];
      const currentState = window.gantt.json.serialize();
      currentState.data = currentState.data.filter(
        (d) => ["button", "header"].includes(d.type) === false
      );

      estimateTemp.gantt = currentState;

      let phaseOrder = 0;

      for (const current of estimateTemp.gantt.data) {

        if (!current.cost_per_hour) {
          current.cost_per_hour = 100;
        }
        if (current.type === "project") {
          if (!current.resource) {
            const fd = data.tasks.find(d => d.data.type === 'project' && d.data.id === current.id);
            if (fd && fd.data.resource) {
              current.resource = fd.data.resource;
            } else if (temp[current.id]) {
              current.resource = temp[current.id];
            }
          }
          const phase = {
            phaseId: current.id,
            phaseOrder: phaseOrder++,
            phaseName: current.resource,
            tasks: [],
          };

          estimateTemp.phases.push(phase);
        }
      }

      let taskOrder = 0;
      for (const current of estimateTemp.gantt.data) {
        if (current.type === "task") {
          if (!current.resource) {
            const fd = data.tasks.find(d => d.data.type === 'task' && d.data.id === current.id);
            if (fd && fd.data.resource) {
              current.resource = fd.data.resource;
            } else if (temp[current.id]) {
              current.resource = temp[current.id];
            }
          }
          const findResource = resources[current.resource_type]?.find(d => d.name === current.resource);
          if (findResource) {
            current.caption = findResource.caption;
            current.name = findResource.name;
            current.title = findResource.title;
          }
          current.amount = parseInt(current.amount || 0);
          current.cost = parseInt(current.cost || 0);
          current.margin = parseInt(current.margin || 0);
          current.costPerHour = parseInt(current.costPerHour || current.cost_per_hour || 0);
          current.resourceName = current.name || "";

          if (current.period_type === "day") {
            current.hours = current.duration * current.hours_per_day;
          }
          const phase = estimateTemp.phases.find((d) => d.phaseId === current.parent);
          if (phase) {
            const task = {
              taskId: current.id,
              taskOrder: taskOrder++,
              taskName: current.resource,
              qty: 1,
              resources: [{ ...current }],
            };
            phase.tasks.push(task);
          }
        }
      }

      for (const current of estimateTemp.gantt.data) {
        if (current.type === "project") {
          const phase = estimateTemp.phases.find((d) => d.phaseId === current.id);
          if (phase) {
            const phaseCalculation = new PhaseCalculation(phase, [], estimateTemp);
            current.margin = phaseCalculation.totalPhaseProfitPercentage();
          }
        }
      }
      const user = fire.auth().currentUser.uid;
      const newEstimateData = Object.assign(estimateTemp, { lastEditor: user });
      // check excludes
      try {
        const mapPrevious = new Map();
        for (const phase of estimatePrevious?.phases) {
          mapPrevious.set(phase.phaseId, phase.exclude || false);
          if (phase?.tasks) {
            for (const task of phase?.tasks) {
              for (const resource of task.resources) {
                mapPrevious.set(resource.id, resource.exclude || false);
              }
            }
          }
        }
        for (const phase of newEstimateData?.phases) {
          if (mapPrevious.has(phase.phaseId)) {
            phase.exclude = mapPrevious.get(phase.phaseId);
          }
          if (phase.tasks) {
            for (const task of phase.tasks) {
              for (const resource of task.resources) {
                if (mapPrevious.has(resource.id)) {
                  resource.exclude = mapPrevious.get(resource.id);
                }
              }
            }
          }
        }
      } catch (err) {
        console.error(err.message);
      }

      const clearUndefined = (obj) => {
        const curType = typeof obj;
        if (curType === 'object') {
          const keys = Object.keys(obj);
          for (const key of keys) {
            if (obj[key] === undefined) {
              console.log(data);
              delete obj[key];
              obj[key] = ''
            } else {
              clearUndefined(obj[key])
            }
          }
        }
      }
      temp = {};
      clearUndefined(newEstimateData)
      await timebasedRef.update(newEstimateData);

      //
      // setEstimate(null);
      const { getEstimateAsync } = props.getEstimateAction;
      await getEstimateAsync(getQuery());
      // setEstimate(clone(newEstimateData));
      // refreshCallBack();
    }
  };
  const [newResources, setNewResources] = useState(0);
  const [newPhases, setNewPhases] = useState(0);
  const [newHeight, setNewHeight] = useState(0);

  const getHeight = () => {
    const phaseHeight = 220 * ((estimateState?.phases?.length || 0)); // todo esref
    let taskHeight = 0;
    if (estimate) {
      const { phases = [] } = estimateState;
      for (const phase of phases) {
        const { tasks = [] } = phase;
        taskHeight += (tasks.length * 40);
        for (const task of tasks) {
          const { resources = [] } = task;
          taskHeight += (resources.length * 10);
        }
      }
    }
    let height = 400 + phaseHeight + taskHeight;
    if (taskHeight === 0) {
      height = 220 + phaseHeight + taskHeight;
    }
    return height;
  }

  const onRoleNameAdded = async (type, data) => {
    if (['internal', 'external'].includes(type)) {
      setNewResources(newResources + 1);
    }
    if (type === 'project') {
      setNewPhases(newPhases + 1);
    }

    return;

    if (data.saveToDb === false) {
      const estimateTemp = clone(estimate);

      const timebasedRef = fire.database().ref(companyId).child(dataType).child(estimateKey);
      if (data.taskType === 'project') {
        if (!estimateTemp.ignorePhase) {
          estimateTemp.ignorePhase = [];
        }
        estimateTemp.ignorePhase.push(data);
      }
      if (data.taskType === 'task') {
        if (!estimateTemp.ignoreTask) {
          estimateTemp.ignoreTask = [];
        }
        estimateTemp.ignoreTask.push(data);
      }
      // await timebasedRef.update(estimateTemp);
      // const { getEstimateAsync } = props.getEstimateAction;
      // await getEstimateAsync(getQuery());
      // setEstimate(clone(estimateTemp));
      // setTemp(data);
    }
    // console.log("ON ROLE NAME ADDED"); // save to db
  };
  const addResource = async (resourceName, taskType, currentType, id) => {
    const query = getQuery();
    temp[id] = resourceName;
    if (taskType === 'task') {
      const newResource = {
        amount: 100,
        amountType: "rate",
        borderColor: "#E70A89",
        caption: "d",
        cost: 70,
        date: "19/09/2023",
        icon: `{"type":{},"compare":null}`,
        resourceName: resourceName,
        subtitle: currentType === 'internal' ? "Role" : "Vendor",
        title: resourceName,
      };

      const resourceRef = fire.database().ref(query.companyId).child("resources");
      const resourceListSnap = await resourceRef.once("value");
      const resourceList = Object.values(resourceListSnap.val() || []);
      if (!resourceList.find(d => d.title === resourceName)) {
        resourceRef.push(newResource);
        setTimeout(() => {
          const { getResourcesAsync } = props.getResourcesAction;
          getResourcesAsync();
        }, 2000);
      }
    }

    if (taskType === 'project') {

      const phaseRef = fire.database().ref(query.companyId).child("phases");
      const phaseListSnap = await phaseRef.once("value");
      const phaseList = Object.values(phaseListSnap.val() || []);
      if (!phaseList.find(d => d.title === resourceName)) {
        phaseRef.push({ title: resourceName });
        const { getPhasesAsync } = props.getPhasesAction;
        await getPhasesAsync();
      }
    }

  };
  const onError = (message) => {
    console.log("ON ERROR ", message);
    return true;
  };
  const [resourcesForTimebased, setTimebasedResources] = useState(null);
  useEffect(() => {
    setTimebasedResources(getTimebasedResourceData());
    setNewHeight(getHeight())
  }, [estimate, resources, phases]);

  const getTimebasedResourceData = () => {
    if (!estimate || !resources || !phases) {
      return null;
    }
    const phasesKeys = Object.keys(phases);
    const newResources = {
      internal: [
      ],
      external: [
      ],
      project: [
      ],
    };

    for (const item of resources) {
      const current = clone(item);
      current.key = current.id;
      current.amount = parseInt(current.amount || 0);
      current.name = current.title;
      current.cost_per_hour = parseInt(current.cost || 0) || 0;

      if (current.amountType === "rate") {
        current.margin =
          (parseInt(current.amount || 0) / current.cost_per_hour - 1) * 100;
      } else {
        current.margin = parseInt(current.amount);
      }

      const itemKey = current.subtitle === "Vendor" ? "external" : "internal";
      newResources[itemKey].push(current);
    }

    for (const phaseKey of phasesKeys) {
      const item = clone(phases[phaseKey]);
      item.name = item.title;
      item.margin = 20;
      newResources.project.push(item);
    }

    return newResources;
  };
  const [fixedOpenCollapsed, setFixedOpenCollapsed] = useState(false);
  const openCollapsed = async () => {
    const timebasedRef = fire.database().ref(companyId).child(dataType).child(estimateKey);
    const snapshot = await timebasedRef.once("value");
    const item = snapshot.val();
    timebasedRef.off("value");
    let changed = false;
    if (item && item.gantt && item.gantt.data) {
      if (!item.data) {
        item.data = emptyData;
        changed = true;
      }
      item.gantt.data.forEach(element => {
        if (element.type === 'project') {
          if (element.open !== true) {
            element.open = true
            changed = true;
          }
        }
      });
      timebasedRef.update(item);
      setEstimate(clone(item));

      if (changed) {
        await getDataFromStore(false);
        setFixedOpenCollapsed(true);
      }
    }
  }

  useEffect(() => {
    getDataFromStore();
    openCollapsed();
  }, []);

  let task = emptyData;

  if (
    estimate &&
    estimate.phases &&
    estimate.phases.length &&
    estimate.phases[0].tasks &&
    estimate.phases[0].tasks.length
  ) {
    task = estimate.phases[0].tasks[0];
  }
  // const resourcesForTimebased = getTimebasedResourceData();

  if (!resourcesForTimebased || !estimate || fixedOpenCollapsed === false || !calculations) {
    return null;
  }

  // const height = getHeight();

  return (
    <div className="gantt-component" style={{ height: newHeight }} key={estimateKey + refreshSignal}>
      <div className={"lightbox"} id="lightbox-view"></div>
      <div className={"names-container"}></div>
      <GanttContainer
        gantt={estimateState?.gantt ? clone(estimateState?.gantt) : null}
        key={estimateKey + refreshSignal}
        task={clone(task) || {}}
        roles={resourcesForTimebased}
        onError={onError}
        onTasksChanged={onTasksChanged}
        onRoleNameAdded={onRoleNameAdded}
        updateCnt={props.updateCnt}
        // props.updateCnt}
        dataType={dataType}
        estimate={clone(estimate)}
        calculations={calculations}
        refreshCallBack={refreshCallBack}
        addResource={addResource}
      />
    </div>
  );
};

function MapStateToProps(state) {
  return {
    estimateState: state.getEstimate.estimate,
    phases: state.getPhases.phases,
    resources: state.getResources.resources,
    company: state.getCompany.company,
    calculations: state.getCompany.calculations,
    refreshSignal: state.getRefreshSignal.refreshSignal
  };
}
function MapDispatchToProps(dispatch) {
  return {
    getEstimateAction: bindActionCreators(
      getEstimateActionCreators,
      dispatch
    ),
    getResourcesAction: bindActionCreators(
      getResourcesActionCreators,
      dispatch
    ),
    getPhasesAction: bindActionCreators(
      getPhasesActionCreators,
      dispatch
    ),
  };
}
export default compose(
  withRouter,
  connect(MapStateToProps, MapDispatchToProps)
)(GanttContainerApp);
