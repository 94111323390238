export default class PhaseCalculation {
    constructor(phase = {}, calculations, estimate) {
        const { billableSwitch = true, definedhourlyRate, profitMargin } = estimate;
        this.definedhourlyRate = null;
        if (billableSwitch && definedhourlyRate) {
            this.definedhourlyRate = definedhourlyRate;
        }
        this.profitMargin = profitMargin;
        this.billableSwitch = billableSwitch;
        this.phase = phase;
        this.calculations = calculations;
        this.tasks = phase.tasks || [];
        this.phaseCalculations = phase.phaseCalculations || {};
        this.resourceHours = this.resourceHours.bind(this);
        this.resourceCalculate = this.resourceCalculate.bind(this);
        this.totalPhaseHours = this.totalPhaseHours.bind(this);
        this.autoCalculationApply = this.autoCalculationApply.bind(this);
        this.totalPhasePrice = this.totalPhasePrice.bind(this);
        this.totalTaskPrice = this.totalTaskPrice.bind(this);
        this.totalPhaseProfit = this.totalPhaseProfit.bind(this);
        this.totalPhaseProfitPercentage = this.totalPhaseProfitPercentage.bind(this);
        this.calculationApply = this.calculationApply.bind(this);
    }

    resourceHours(resource, applyAuto = true) {
        if (this.phase.exclude !== true && resource && resource.resourceName && resource.exclude !== true) {
            if (applyAuto) {
                return this.autoCalculationApply(parseFloat(resource.hours || 0)) * parseFloat(resource.qty || 1);
            }
            return parseFloat(resource.hours || 0) * parseFloat(resource.qty || 1);
        }
        return 0;
    }
    resourceCalculate(resource, field = 'cost', applyAuto = true) {
        const getPercent = (p = 0) => 1 + (p / 100);
        if (this.phase.exclude !== true && resource && resource.exclude !== true) {
            let val = parseFloat(resource[field] || 0);
            if (field === 'amount') {
                const amountType = resource.amountType || resource.amounttype || 'rate';
                if (amountType === 'rate') {
                    const vendorRate = this.profitMargin ? getPercent(parseFloat(this.profitMargin)) * parseFloat(resource.amount) : parseFloat(resource.amount || 0);
                    val = vendorRate;
                } else {
                    const vendorUpSell = this.profitMargin ? parseFloat(this.profitMargin) : parseFloat(resource.amount || 0);
                    val = parseInt(resource.cost || 0) * getPercent(vendorUpSell);
                }
                if (this.profitMargin) {
                    const vendorRate = getPercent(parseFloat(this.profitMargin || 0)) * parseFloat(resource.amount)
                    val = vendorRate;
                }
                if (this.definedhourlyRate && (resource.type === 'Vendor' || resource.subtitle === 'Vendor') === false) {
                    val = parseFloat(this.definedhourlyRate);
                }
                if (this.billableSwitch === false) {
                    val = parseInt(resource.cost || 0);
                }
            }
            const cuResourceCost = this.resourceHours(resource, applyAuto) * val;
            const strcuResourceCost = parseFloat(cuResourceCost.toFixed(2));
            return strcuResourceCost;
        }
        return 0;
    }
    totalPhaseHours(applyAuto = true) {
        if (this.phase.exclude !== true && this.tasks && this.tasks.length) {
            let total = 0;
            for (const task of this.tasks) {
                if (task.resources && task.exclude !== true) {
                    for (const resource of task.resources) {
                        const resHorus = this.resourceHours(resource, applyAuto);
                        total += resHorus * (task.qty || 1);
                    }
                }
            }
            return total;
        }
        return 0;
    };
    calculationApply(calculation, current, total) {
        const { phaseCalculationsOverwrite = {} } = this.phase;
        let figure = calculation.figure || 0;
        const phaseOverwrite = phaseCalculationsOverwrite[calculation.id];
        if (phaseOverwrite) {
            figure = phaseOverwrite.figure && phaseOverwrite.figure !== '' ? phaseOverwrite.figure : 0;
        }
        if (this.phaseCalculations[calculation.id] === true) {
            total += current * (parseFloat(figure || 0) / 100) * (calculation.type === 'positive' ? 1 : -1);
        }
        return total
    }

    autoCalculationApply(current) {
        let total = 0;
        if (this.calculations && this.calculations.length) {
            for (const calculation of this.calculations) {
                total = this.calculationApply(calculation, current, total)
            }
        }
        return (current + total);
    }
    totalPhasePrice(applyAuto = true, applyQuantity = true) {
        if (this.phase.exclude !== true && this.tasks && this.tasks.length) {
            let total = 0;
            for (const task of this.tasks) {
                if (task.resources && task.exclude !== true) {
                    for (const resource of task.resources) {
                        total += this.resourceCalculate(resource, 'amount', applyAuto) * (applyQuantity && task.qty ? task.qty : 1);
                    }
                }
            }
            return total;
        }
        return 0;
    }
    totalPhaseCost(applyAuto = true) {
        let total = 0;
        if (this.phase.exclude !== true && this.tasks && this.tasks.length) {
            for (const task of this.tasks) {
                if (task.resources && task.exclude !== true) {
                    for (const resource of task.resources) {
                        total += this.resourceCalculate(resource, 'cost', applyAuto) * (task.qty || 1);
                    }
                }
            }
            return total;
        }
        return total;
    };
    totalPhaseProfit() {
        return this.totalPhasePrice() - this.totalPhaseCost();
    }
    totalTaskPrice(task, applyAuto = true, applyQuantity = true) {
        let total = 0;
        if (this.phase.exclude !== true && task && task.resources) {
            for (const resource of task.resources) {
                total += this.resourceCalculate(resource, 'amount', applyAuto) * (applyQuantity && task.qty ? task.qty : 1);
            }
        }
        return total;
    }
    totalPhaseProfitPercentage() {
        const profit = this.totalPhaseProfit();
        const cost = this.totalPhaseCost();

        if (!cost) {
            return 0;
        }
        return Math.round((profit / cost) * 100);
    }
}