import React from "react";
import "../../assets/scss/setting-table.scss";
import TaskBased from './TaskBased';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TimeBased from "./TimeBased";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
export default function SettingsTable(props) {
  const { estimateType = '', shared = false, updateCnt, update } = props;
  const isTimebased = estimateType.includes('-timebased');

  return (
    <div className="summary-details">
      {isTimebased ? <TimeBased shared={shared} updateCnt={updateCnt}
        update={update} estimateType={estimateType} /> : <TaskBased shared={shared} updateCnt={updateCnt} update={update} estimateType={estimateType} />}
    </div>
  )
}