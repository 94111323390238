import FormatMoneyStrFriendly from "../../../../../../libs/formatMoneyStrFriendly";

class Helper {
	constructor() {
	}

	static get typeSizes() {
		return {
			project: {
				row_height: 130,
				bar_height: 35
			},
			task: {
				row_height: 50,
				bar_height: 35
			}
		};
	}

	static addHeaderProject(phaseId) {
		gantt.addTask({
			id: gantt.uid(),
			type: "header"
		}, phaseId, 0);
	}

	static addButtonToProject(phaseId) {

		gantt.addTask({
			id: gantt.uid(),
			type: "button"
		}, phaseId);
	}

	static addNewTaskToProject(phaseId, resourceType) {
		gantt.addTask({
			resource: "",
			type: "task",
			hours_per_day: 0,
			cost_per_hour: 0,
			start_date: new Date(),
			duration: 1,
			costs: [1, 2, 3],
			resource_type: resourceType,
			qty: 1
		}, phaseId);
	}

	static addNewProject() {

		const phaseId = gantt.uid();
		const sizes = this.typeSizes.project;

		gantt.addTask({
			id: phaseId,
			resource: "",
			type: "project",
			$open: true,
			row_height: sizes.row_height,
			bar_height: sizes.bar_height,
			margin: 0
		});

		this.addHeaderProject(phaseId);
		this.addButtonToProject(phaseId);
		gantt.showTask(phaseId);
	}

	static getCountOfProjectSpecialTasks() {
		return 2;
	}

	static getHeightOfClosedSummary() {
		return this.getScaleHeight();
	}

	static getHeightOfSummary() {
		// 3 - count of rows: hours, cost, price
		return this.getScaleHeight() + 3 * this.typeSizes.task.row_height + 1;
	}

	static getScaleHeight() {
		return 60;
	}

	static numberRounding(number) {
		// rounding to 10th
		return Math.round(number * 10) / 10;
	}

	static convertPrice(number) {
		return FormatMoneyStrFriendly({ number: number, money: true, maximumFractionDigits: 0 })
	}

	static deleteTask(taskId, closeLightbox, name) {
		const confirmWindow = gantt.confirm({
			id: "customeConfirmMessage",
			title: "Are you sure?",
			type: "closeLightbox",
			text: `Do you want to permanently delete this ${name} ? <i class='close-confirmWindow'></i>`,
			ok: "Delete",
			cancel: "Cancel",
			callback(answer) {
				if (answer && !closeLightbox) {
					gantt.deleteTask(taskId);
				}
				else if (answer && closeLightbox) {
					gantt.deleteTask(taskId);
					gantt.hideLightbox();
				}
			}
		});

		this.modalbox = confirmWindow;
		this.hideConfirmWindow();
	}

	static hideConfirmWindow() {
		const closeBtn = document.querySelector(".close-confirmWindow");
		closeBtn.onclick = () => {
			gantt.modalbox.hide(this.modalbox);
		}
	};

	static globalHideConfirmWindow() {
		if (this.modalbox) {
			gantt.modalbox.hide(this.modalbox);
		}
	}

	static getAbbreviation(nameValue) {
		const serchingCapitalLettersRegex = /\w+/g;
		const capitalLetters = nameValue.match(serchingCapitalLettersRegex);

		return capitalLetters[0];
	}
}

export default Helper;
