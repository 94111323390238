import fire from "../../config/firebase";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
    refreshSignal: 0,
    status: 'loading'
};
// https://blog.logrocket.com/smarter-redux-redux-toolkit/

export const getRefreshSignalAsync = createAsyncThunk(
    'refreshSignal/getRefreshSignal',
    async () => {
        return { refreshSignal: new Date().getTime() };
    }
);

// Redux Toolkit slice
export const getRefreshSignalSlice = createSlice({
    name: 'refreshSignal',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRefreshSignalAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getRefreshSignalAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.refreshSignal = action.payload.refreshSignal
            });
    },
});
export default getRefreshSignalSlice.reducer;
