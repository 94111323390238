import GridSettings from "./GridSettings";
import TimelineSettings from "./TimelineSettings";
import ResourceMenu from "./ResourceMenu";
import Helper from "../Helper";
import FormatHour from "../../../../../../libs/formatHour";
import formatMoneyTimeBased from "../../../../../../libs/formatMoneyTimeBased";
import PhaseCalculation from "../../../../../small-components/Phase/phaseCalculationUtil";
import EstimateCalculationUtil from "../../../../../small-components/Phase/estimateCalculationUtil";
export default class GanttChart {
	constructor(resourceView, estimate, resources, refreshCallBack, addResource, calculations) {
		this.resourceView = resourceView;
		this.gridSettings = new GridSettings(this.setColumnsConfig, this.resourceView, estimate?.gantt?.data);
		this.timelineSettings = new TimelineSettings();
		this.addResource = addResource;
		this.resourceMenu = new ResourceMenu(resources, this.addResource);
		this.taskCounter = null;
		this.estimate = estimate;
		this.refreshCallBack = refreshCallBack;
		this.calculations = calculations;
	}

	init() {
		this.setConfig();
		this.gridSettings.init(this.estimate, this.refreshCallBack);
		this.timelineSettings.init();
		this.resourceMenu.init();
		this.addCustomeTask();
		this.hideEmptyBarsOnTimeline();
	}

	setConfig() {
		gantt.config.columns = this.setColumnsConfig.opened;
		gantt.customeSettings = {};
	}

	onUpdateRoleNames(roles) {
		this.resourceMenu.updateResources(roles);
	}

	update(est) {
		this.estimate = est;
		this.gridSettings.update(est.gantt.data);
	}

	get setLayout() {
		return {
			rows: [
				{
					cols: [
						{ view: "grid", group: "grids", scrollY: "scrollVer" },
						{ view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
						{ view: "scrollbar", id: "scrollVer" }
					]
				}
			]
		};
	}

	get setColumnsConfig() {
		return {
			opened: [
				{
					label: this.getGridScaleLayout(),
					name: "resource",
					min_width: 350,
					template: (task) => {
						switch (task.type) {
							case "task":
								return this.setResourceLayoutForTask(task);
							case "header":
								return "<span class='header text--right'>resources</span>";
							case "button":
								return this.setButtonLayout(task);
							case "project":
								return this.setResourceLayoutForProject(task, 'project');
							default:
								return "";
						}
					}
				},
				{
					name: "qty",
					min_width: 70,
					width: "*",
					template: () => ""
				},
				{
					name: "qty",
					min_width: 115,
					width: "*",
					template: (task) => {
						switch (task.type) {
							case "task":
								return this.setQtyLayout(task);
							case "header":
								return "<span class='header'>qty</span>";
							case "button":
								return "";
							case "project":
								return "";
							default:
								return "";
						}
					}
				},
				{
					name: "qty",
					min_width: 55,
					width: "*",
					template: (task) => {
						switch (task.type) {
							case "task":
								return "<span class='qty-delete'></i></span>";
							case "header":
								return "";
							case "button":
								return "";
							case "project":
								return "";
							default:
								return "";
						}
					}
				},
				{
					name: "duration",
					min_width: 49,
					width: "*",
					label: " ",
					template: (task) => {
						switch (task.type) {
							case "task":
								return this.setHoursLayout(task);
							case "header":
								return "<span class='header'>hours</span>";
							case "button":
								return "";
							case "project":
								return "";
							default:
								return "";
						}
					}
				},
				{
					name: "qty",
					min_width: 50,
					width: "*",
					align: "center",
					template: (task) => {
						switch (task.type) {
							case "task":
								return this.setDecorativeDotsLayout(task);
							case "header":
								return "";
							case "button":
								return "";
							case "project":
								return "";
							default:
								return "";
						}
					}
				}
			],
			closed: [
				{
					label: this.getGridScaleLayout(),
					name: "resource",
					width: 240,
					template: (task) => {
						switch (task.type) {
							case "task":
								return this.setAbbreviationLayout(task);
							case "header":
								return "<span class='header text--right'>resources</span>";
							case "button":
								return this.setButtonLayout(task);
							case "project":
								return this.setResourceLayoutForProject(task);
							default:
								return "";
						}
					}
				}
			]
		};
	}

	hideEmptyBarsOnTimeline() {
		const shouldTaskBarBeHidden = task => task.type === "task" && task.hours_per_day === 0;
		const shouldProjectBarBeHidden = (task) => {
			let shouldHideBar = false;
			if (task.type === "project") {
				const childIds = gantt.getChildren(task.id);
				if (childIds.length > Helper.getCountOfProjectSpecialTasks()) {
					const hours = childIds.reduce((sum, id) => {
						let taskHours = 0;
						const child = gantt.getTask(id);
						if (child && child.type === "task") {
							taskHours = child.hours_per_day || 0;
						}

						return sum + taskHours;
					}, 0);

					if (hours === 0) {
						shouldHideBar = true;
					}
				}
				else {
					shouldHideBar = true;
				}
			}

			return shouldHideBar;
		};

		gantt.attachEvent("onBeforeDataRender", () => {
			gantt.eachTask((task) => {
				task.hide_bar = shouldTaskBarBeHidden(task) || shouldProjectBarBeHidden(task);
			});
		});
	}

	addCustomeTask() {
		gantt.attachEvent("onParse", () => {
			gantt.eachTask((task) => {

				const isProject = task.type === gantt.config.types.project;
				const isTask = task.type === gantt.config.types.task;

				if (isProject) {
					const sizes = Helper.typeSizes.project;
					task.row_height = sizes.row_height;
					task.bar_height = sizes.bar_height;

					Helper.addHeaderProject(task.id);
					Helper.addButtonToProject(task.id);
				}
				else if (isTask) {
					task.costs = [1, 2, 3];
				}
			});
		});
	}

	getGridScaleLayout() {
		if (!localStorage.level) {
			localStorage.level = "weeks";
		}
		const numberOfPeriods = ["Days", "Weeks", "Months", "Years"];
		const nodeWithPeriods = [];

		numberOfPeriods.forEach(periodName => {
			const lowerCaseName = periodName.toLowerCase();
			const isItActivePeriod = localStorage.level === lowerCaseName;
			const activePeriodClass = isItActivePeriod ? "active" : "";
			const period = `<li class='period-type ${activePeriodClass} ' data-value='${lowerCaseName}'>${periodName}</li>`;

			nodeWithPeriods.push(period);
		});

		return [
			"<div class='control-panel'>",
			"<div class='gantt-select'>",
			"<span class='closed-container'></span>",
			`<input id='change-zooming' type='text'>`,
			"<ul class='period-container'>",
			`${nodeWithPeriods.join("")}`,
			"</ul>",
			"</div>",
			"<div class='grid_toggle'></div>",
			"</div>",
		].join("");
	}

	setQtyLayout(task) {
		const qty = task.qty;
		const isOneQty = qty === 1 || null;
		const firstIconClassName = isOneQty ? "delete-task" : "btn_circle_minus";
		const focusId = gantt.customeSettings.focusId;
		const focusClass = focusId === task.id ? "focus" : "";
		const exclude = this.getExcludeValue(task);
		return `
			<div class="qty-counter-animation qty-container ${focusClass} ">
				<span class="qty-counter-animation counter  ${exclude ? 'exclude' : 'include'} " data-qty-task-id="${task.id} ">${qty}</span>
				<span class="qty-counter-animation minus moving ${firstIconClassName}"></span>
				<span class="qty-counter-animation add moving btn_circle_plus"></span>
			</div>`;
	}

	setResourceLayoutForTask(task) {
		const exclude = this.getExcludeValue(task);
		const resourceType = task.resource_type;
		const resourceName = task.resource;
		const resourceElement = this.resourceMenu.setLayout(resourceName, resourceType, task, exclude);
		return resourceElement;
	}
	setPhaseToggleUpdate(phase) {
		phase.open = phase.$open
	}

	getExcludeValue(phaseOrResource) {
		const { phases = [] } = this.estimate;
		const dataType = phaseOrResource?.type === "project" ? "phase" : "resource";
		if (dataType === 'phase') {
			const phase = phases?.find(p => p.phaseId === phaseOrResource.id);
			return phase && phase.exclude === true;
		} else if (dataType === 'resource' && phases) {
			for (let p = 0; p < phases.length; p++) {
				for (let t = 0; t < phases[p].tasks?.length; t++) {
					for (let r = 0; r < phases[p].tasks[t].resources?.length; r++) {
						if (phases[p].tasks[t].resources[r].id === phaseOrResource.id) {
							return phases[p].tasks[t].resources[r].exclude === true || phases[p].exclude === true;
						}
					}
				}
			}
		}
		return false

	}

	setResourceLayoutForProject(phaseOrResource) {

		const exclude = this.getExcludeValue(phaseOrResource);
		const resourceName = phaseOrResource.resource;
		const resourceType = phaseOrResource.resource_type;

		const resourceElement = this.resourceMenu.setLayout(resourceName, resourceType, phaseOrResource);

		const correctiveSteps = 2;
		const resourcesAmount = gantt.getChildren(phaseOrResource.id).length - correctiveSteps;
		const arrayWithCalculation = this.getArrayWithPhaseValues(phaseOrResource);
		const closedCss = phaseOrResource.$open ? "" : "closed";
		// const estimateCalculator = new EstimateCalculationUtil(this.estimate, this.calculations);

		// // const { hours, totalCosts, totalPrice } = this.calculatePhaseValues(phaseOrResource, arrayWithCalculation);
		// const totalEstimatePrice = estimateCalculator.totalEstimatePrice();
		// const totalEstimateCost = estimateCalculator.totalEstimateCost();
		// const totalEstimateHours = estimateCalculator.totalEstimateHours();

		const { hours, totalCosts, totalPrice } = this.calculatePhaseValues(phaseOrResource, arrayWithCalculation);
		const setDecorativeDotsLayout = this.setDecorativeDotsLayout(phaseOrResource);
		const resourceTemplate = [
			"<div class='phase-container'>",
			`<div class='phase_col phase_info   ${exclude ? ' exclude' : ' include'} '>`,
			"<span class='phase_title'>phase</span>",
			`${resourceElement}`,
			`<span class='phase_resources'>${resourcesAmount} Resources</span>`,
			"</div>",
			`<div class='phase_col phase_hours ${exclude ? ' exclude' : ' include'} '>`,
			"<span class='phase_title text--right'>hours</span>",
			`<span class='phase_name'>${FormatHour({ number: hours, tail: '' })}</span>`,
			"</div>",
			`<div class='phase_col phase_cost ${exclude ? ' exclude' : ' include'} '>`,
			"<span class='phase_title'>cost</span>",
			`<span class='phase_name'>${formatMoneyTimeBased({ number: totalCosts, money: true, maximumFractionDigits: 0 })}</span>`,
			"</div>",
			`<div class='phase_col phase_price ${exclude ? ' exclude' : ' include'} '>`,
			"<span class='phase_title text--right'>price</span>",
			`<span class='phase_name'>${formatMoneyTimeBased({ number: totalPrice, money: true, maximumFractionDigits: 0 })}</span>`,
			"</div>",
			"<div class='phase_col phase_actions'>",
			`<div class='phase_toggle  ${this.setPhaseToggleUpdate(phaseOrResource)} ${closedCss}'></div>`,
			`${setDecorativeDotsLayout}`,
			"</div>",
			"</div>"
		]

		return resourceTemplate.join("");
	}

	setHoursLayout(task) {
		const hours = this.gridSettings.workingHoursCalculation(task);
		const roundedHours = Helper.numberRounding(hours);
		const hoursPerDay = task.hours_per_day;
		const setTimeButton = "<span class='openingLightboxBtn tooltip'></span>";
		const exclude = this.getExcludeValue(task);
		if (hoursPerDay === 0) {
			return setTimeButton;
		}

		return `<span class="hours ${exclude ? 'exclude' : 'include'}">${roundedHours}</span>`;
	}
	// <MoreVertOutlinedIcon htmlColor='#000' className='open-close' onClick={this.openDrop1} ref={this.wrapperRef1} />

	setDecorativeDotsLayout(phaseOrResource) {
		const exclude = this.getExcludeValue(phaseOrResource)

		const layout = `
		<div class="task-operation">
				<span class='dots-option'><svg class="MuiSvgIcon-root open-close" focusable="false" viewBox="0 0 24 24" color="#000" aria-hidden="true"><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg></span>
				<div class="task-menu">
					<div class="task-dublicate new-action">
						<span class="icon"></span>	
						<span class="text">Duplicate</span> 
					</div>
					<div class="task-exclude new-action   " >
						<span class="icon ${exclude ? 'iconInclude' : 'iconExclude'}"></span>
						<span class="text">${exclude ? 'Include' : 'Exclude'}</span>
					</div>
					<div class="task-delete new-action">
						<span class="icon"></span>	
						<span class="text">Delete</span> 
					</div>
				</div>
		</div>`;

		return layout;
	}

	setAbbreviationLayout(task) {
		const resourceName = task.resource;
		const iconName = task.resource_type || "none";
		//const abbreviation = resourceName ? Helper.getAbbreviation(resourceName) : "Empty";
		const abbreviation = resourceName ? (resourceName.length > 10 ? resourceName.substring(0, 10) + '...' : resourceName) : "Empty";

		return [
			`<div class='abbreviation'>
			<div class='resource-icon'>
			<span class='role-icon ${iconName.toLowerCase()}'></span>
			</div>
			<span class="abbreviation-position">${abbreviation}</span>
			</div>`
		]
	}

	setButtonLayout() {
		return `
	<div class="addingNewTaskButton-container ">
			<span class="opening-button tooltip"></span>
			<span class="role-resourceBtn tooltip" data-resource-type="internal"></span>
			<span class="vendor-resourceBtn tooltip" data-resource-type="external"></span>
		</div>`;
	}

	getArrayWithPhaseValues(phase) {
		const phaseId = phase.id;
		const resourcesInPhase = gantt.getChildren(phaseId);
		const result = [];
		resourcesInPhase.forEach((taskId) => {
			const resource = gantt.getTask(taskId);
			if (resource.type !== "task") { return; }
			// const rateFromEstimate = resource.amount;
			for (const phase of this.estimate.phases) {
				if (phase?.tasks) {
					for (const task of phase.tasks) {
						for (const estResource of task.resources) {
							if (estResource.id === resource.id) {
								if (estResource.amount) {
									resource.amount = estResource.amount;
								}
							}
						}
					}
				}
			}

			const phaseCalculation = new PhaseCalculation({
				tasks: [{
					qty: 1,
					resources: [{
						resourceName: resource.name,
						cost: resource.cost_per_hour || 0,
						amountType: resource.amountType,
						amount: resource.amount,
						hours: resource.hours,
						qty: resource.qty || 0,
						type: resource.resource_type === "internal" ? '' : 'Vendor'
					}]
				}]
			}, this.calculations, this.estimate || {});
			const totalPhaseCost = phaseCalculation.totalPhaseCost();
			const totalPhaseHours = phaseCalculation.totalPhaseHours();
			const totalPhasePrice = phaseCalculation.totalPhasePrice();

			const curCalculations = {
				phaseId,
				hours: totalPhaseHours,
				totalCosts: totalPhaseCost,
				totalPrice: totalPhasePrice
			};

			result.push(curCalculations);
		});

		return result;
	}

	calculatePhaseValues(phase, calculations) {
		const objectWithStartValues = { phaseId: "", hours: 0, totalCosts: 0, totalPrice: 0 };
		const sumOfValues = calculations.reduce((acc, value) => {
			acc.hours += value.hours;
			acc.totalCosts += value.totalCosts;
			acc.totalPrice += value.totalPrice;
			acc.phaseId = value.phaseId;
			return acc;
		}, objectWithStartValues);
		return sumOfValues;
	}

	convertValues(sumOfValues) {

		sumOfValues.hours = Number.isInteger(sumOfValues.hours)
			? sumOfValues.hours
			: Helper.numberRounding(sumOfValues.hours);
		sumOfValues.totalCosts = Helper.convertPrice(sumOfValues.totalCosts);
		sumOfValues.totalPrice = Helper.convertPrice(sumOfValues.totalPrice);

		return sumOfValues;
	}
}
