import Helper from "../Helper";
// import Zoom from "../Zoom";
import fire from "../../../../../../config/firebase";

export default class GridSettings {
	constructor(gridConfig, resourceView, ganntInfo) {
		this.resourceView = resourceView;
		this.gridConfig = gridConfig;
		this.ganntInfo = ganntInfo
		this.lightboxToggle = false;
		this.isGridClosed = false;
		this.currentScrollPosition = {};
		this.numberOfTasksInGrid = 0;
		this.exclude = this.exclude.bind(this);
		this.include = this.include.bind(this);
		this.getQuery = this.getQuery.bind(this);
		this.updateResource = this.updateResource.bind(this);
		this.getExcludeValue = this.getExcludeValue.bind(this)
	}

	init(estimate, refreshCallBack) {
		this.addCustomeClasses();
		this.attachEventsOnHeaderInput();
		this.attachEventsOnQty();
		this.attachAnimationEventOnQty();
		this.attachEventsOnResourceButton();
		this.attachEventOnAddNewPhaseBtn();
		this.attachEventOnAddingTaskButton();
		this.attachEventOnPhaseToggle();
		this.attachEventOnGridToggle();
		this.allowMoveOfPhasesOnly();
		this.setLightboxStatus();
		this.attachEventOnModalWindow();
		this.estimate = estimate;
		this.refreshCallBack = refreshCallBack;
	}
	update(est) {
		this.ganntInfo = est;
	}
	addCustomeClasses() {
		const isOddDay = date => gantt.getScale().trace_indexes[+date] % 2;
		const isResource = task => task.type === gantt.config.types.task || task.type === "histogram_resource";
		const isProject = task => task.type === gantt.config.types.project;
		const isButton = task => task.type === "button";

		gantt.templates.resource_cell_class = () => "histogram-resource-cell";
		gantt.templates.timeline_cell_class = (task, date) => {
			const unit = gantt.getScale().unit;
			const isItDay = unit === "day";
			const todayDate = isItDay ? gantt.date.date_part(new Date()) : gantt.date.add(new Date(), -1, `${unit}`);

			let css = "";
			css += !isOddDay(date) ? "timeline-cell-with-bg " : "";

			if (date.getTime() < todayDate.getTime()) {
				css += "timeline-cell-with-bg-shadow";
			} else {
				css += "";
			}

			return css;
		};

		gantt.templates.task_row_class = (start, end, task) => {
			let css = "";
			css += isResource(task) ? "timeline-row-with-border " : "";
			css += isProject(task) ? "timeline-project-row-with-border " : "";
			css += isProject(task) && !task.$open ? "closed " : "";
			css += isButton(task) ? "timeline-button-with-border " : "";

			return css;
		};

		gantt.templates.grid_row_class = (start, end, task) => {
			let css = "";
			css += isResource(task) ? "grid-row-with-border " : "";
			css += isButton(task) ? "button-with-border " : "";
			css += isProject(task) && !task.$open ? "closed " : "";

			return css;
		}

		gantt.templates.grid_header_class = (columnName) => {
			let css = "";

			if (columnName === "resource" && this.isGridClosed) {
				css += "grid_toggle--closed";
			}
			const closed = this.resourceView.getIsSummaryClosed();
			if (columnName === "summary" && closed) {
				css += "summary_toggle--closed";
			}
			return css;
		};
	}

	closeSelectedMenu(className, activeState) {
		const isItResourceNames = className === "names-container";
		const openSelects = document.querySelectorAll(`.${className}`);
		const isItClosedContainer = className === "closed-container";

		if (isItResourceNames) {
			const lines = document.querySelectorAll(".line");
			const inputs = document.querySelectorAll(".enter-resource");

			lines.forEach(item => item.classList.remove("static"));
			inputs.forEach(item => item.classList.remove("cursore-active"));
		}

		if (isItClosedContainer && gantt?.$container) {
			const periodContainer = gantt.$container.querySelector(".period-container");
			periodContainer.classList.remove("active");
		}

		openSelects.forEach(item => item.classList.remove(activeState));
	}

	closeOnWindow(closingClass) {
		window.addEventListener("click", (event) => {
			const className = () => {
				switch (closingClass) {
					case "names-container":
						return "enter-resource";
					case "addingNewTaskButton-container":
						return "opening-button";
					default:
						return closingClass;
				}
			};
			const openedSelectStatus = event.target.classList.contains(className());

			if (!openedSelectStatus) {
				this.closeSelectedMenu(closingClass, "active");
			}
		});
	}

	attachEventsOnHeaderInput() {
		gantt.attachEvent("onGridHeaderClick", (name, event) => {
			const periodContainer = gantt.$container.querySelector(".period-container");
			const inputContainer = "closed-container";

			if (event.target && event.target.classList.contains(inputContainer)) {
				event.target.classList.toggle("active");
				periodContainer.classList.toggle("active");
			}

			if (event.target && event.target.classList.contains("period-type")) {
				const periodName = event.target.dataset.value;
				this.setValueInHeaderInput(periodName);
			}
		});

		this.closeOnWindow("closed-container");
	}

	setValueInHeaderInput(period) {
		const periodContainer = gantt.$container.querySelector("#change-zooming");

		periodContainer.value = period;
		periodContainer.dispatchEvent(new Event('change'));
	}

	attachEventsOnQty() {
		gantt.attachEvent("onTaskClick", (id, event) => {
			const phaseId = gantt.getParent(id);
			const target = event.target;
			const plusResourceBtn = target.classList.contains("add");
			const minusResourceBtn = target.classList.contains("minus");
			const deleteResorceBtn = target.classList.contains("delete-task");
			const addNewResource = ['vendor-resourceBtn tooltip', 'role-resourceBtn tooltip'].includes(target.classList.value);

			if (addNewResource) {
				setTimeout(() => {
					gantt.callEvent('onRoleNameAdded', ['internal', {}]);
				}, 1000);
			}

			if (plusResourceBtn) {
				this.plusResource(id);
				gantt.updateTask(phaseId);
			}
			else if (deleteResorceBtn) {
				Helper.deleteTask(id);
				gantt.updateTask(phaseId);
			}
			else if (minusResourceBtn) {
				this.minusResource(id);
				gantt.updateTask(phaseId);
			}

			return true;
		});
	}

	attachAnimationEventOnQty() {
		gantt.attachEvent("onMouseMove", (id, event) => {
			const element = event.target;
			const isItQty = element.classList.contains("qty-counter-animation");

			if (isItQty) {
				const isItCounter = element.classList.contains("counter");
				const qtyContainer = element.parentNode;

				if (isItCounter) {
					if (gantt.customeSettings.focusId !== id) {
						this.deleteFocus();
					}

					gantt.customeSettings.focusId = id;
					gantt.customeSettings.qtyContainer = qtyContainer;
					qtyContainer.classList.add("focus");
				}
			}
			else if (gantt.customeSettings.focusId) {
				this.deleteFocus();
				if (id && gantt.isTaskExists(id)) {
					gantt.refreshTask(id);
				}
			}
		});
	}

	deleteFocus() {
		const qtyContainer = gantt.customeSettings.qtyContainer;

		if (qtyContainer) {
			qtyContainer.classList.remove("focus");
		}

		gantt.customeSettings.focusId = null;
		gantt.customeSettings.qtyContainer = null;
	}

	plusResource(taskId) {
		const task = gantt.getTask(taskId);
		const qty = task.qty;
		const step = 1;
		const maxQtyValue = 1000;

		if (task.qty === maxQtyValue) {
			return
		}

		task.qty = qty + step;
		gantt.updateTask(taskId);
	}

	minusResource(taskId) {
		const task = gantt.getTask(taskId);
		const qty = task.qty;
		const step = 1;

		if (qty > 1) {
			task.qty = qty - step;
			gantt.updateTask(taskId);
		}
	}

	workingHoursCalculation(task) {
		const numberOfDays = task.duration || 0;
		const hoursPerDay = task.hours_per_day || 0;
		const hours = numberOfDays * hoursPerDay;
		return hours;
	}

	attachEventsOnResourceButton() {
		gantt.attachEvent("onTaskClick", (id, event) => {
			const target = event.target;
			const isItSelectElement = target.classList.contains("task-operation");
			const isItAction = target.classList.contains("new-action");

			if (isItSelectElement) {
				this.closeSelectedMenu("task-operation", "active");
				target.classList.add("active");
				return false;
			}
			else if (isItAction) {
				const actionType = target.innerText.toLowerCase();
				this.setActionOnResourceButton(actionType, id);

				return false;
			}

			return true;
		});

		this.closeOnWindow("task-operation");
	}

	setActionOnResourceButton(actionType, itemId) {

		const phaseOrResource = gantt.getTask(itemId);
		const taskType = phaseOrResource.type;

		const duplicateMethod = {
			task: this.dublicateTask.bind(this),
			project: this.dublicateProject.bind(this)
		};
		const index = this.ganntInfo.findIndex((item) => item.id === Number(itemId));

		const name = this.ganntInfo[index] && this.ganntInfo[index].type === "project" ? "phase" : "resource";
		switch (actionType) {
			case "duplicate":
				duplicateMethod[taskType](phaseOrResource, itemId);
				break;
			case "delete":
				Helper.deleteTask(itemId, undefined, name);
				break;
			case "exclude":
				this.exclude(name, phaseOrResource);
				break;
			case "include":
				this.include(name, phaseOrResource);
				break;
			default:
				break;
		}
	}
	getQuery() {
		const companyId = localStorage.getItem('companyId');
		const arr = window.location.pathname.split('/');
		const estimateKey = arr[arr.length - 1];
		const dataType = window.location.pathname.includes("estimates")
			? "estimates"
			: "templates";
		return { dataType, estimateKey, companyId, shared: false }
	}
	updatePhase(phase, data) {
		const { phases } = this.estimate || {};
		if (!phases.length) {
			return;
		}
		const query = this.getQuery();
		const phaseIndex = phases.findIndex(p => p.phaseId === phase.id);

		if (phaseIndex >= 0) {
			const refInner = fire.database().ref(query.companyId).child(query.shared ? query.estimateKey : `${query.dataType}/${query.estimateKey}`);
			refInner
				.child("phases")
				.child(phaseIndex)
				.update(data);
		}
	}
	updateResource(resource, data) {
		const { phases } = this.estimate || {};
		if (!phases.length) {
			return;
		}
		const query = this.getQuery();
		let phaseIndex = -1, resourceIndex = -1, taskIndex = -1;

		for (let p = 0; p < phases.length; p++) {
			for (let t = 0; t < phases[p].tasks.length; t++) {
				for (let r = 0; r < phases[p].tasks[t].resources.length; r++)
					if (phases[p].tasks[t].resources[r].id === resource.id) {
						phaseIndex = p;
						taskIndex = t;
						resourceIndex = r;
						break;
					}
			}
		}
		if (phaseIndex >= 0) {
			const refInner = fire.database().ref(query.companyId).child(query.shared ? query.estimateKey : `${query.dataType}/${query.estimateKey}`);
			refInner
				.child("phases")
				.child(phaseIndex)
				.child("tasks")
				.child(taskIndex)
				.child('resources')
				.child(resourceIndex)
				.update(data);
		}
	}

	include(name, phaseOrResource) {
		if (name === 'phase') {
			this.updatePhase(phaseOrResource, { exclude: false });
		}
		if (name === 'resource') {
			this.updateResource(phaseOrResource, { exclude: false });
		}
		if (this.refreshCallBack) {
			this.refreshCallBack();
		}

	}
	exclude(name, phaseOrResource) {
		if (name === 'phase') {
			this.updatePhase(phaseOrResource, { exclude: true });
		}
		if (name === 'resource') {
			this.updateResource(phaseOrResource, { exclude: true });
		}
		if (this.refreshCallBack) {
			this.refreshCallBack();
		}
	}

	dublicateProject(project, projectId) {
		const exclude = this.getExcludeValue(project)
		const newProjectId = gantt.uid();
		const cloneProject = { ...project, id: newProjectId };
		cloneProject.exclude = exclude
		gantt.addTask(cloneProject, project.parent, project.$index + 1);
		const tasksChildrens = gantt.getChildren(projectId);
		tasksChildrens.forEach((taskId) => {
			const task = gantt.getTask(taskId);
			const taskType = task.type;
			if (taskType === "task") {
				const exclude = this.getExcludeValue(task)
				const cloneTask = { ...task, id: gantt.uid() }
				cloneTask.exclude = exclude
				gantt.addTask(cloneTask, newProjectId);
			}
		});

		Helper.addHeaderProject(newProjectId);
		Helper.addButtonToProject(newProjectId);
		gantt.showTask(newProjectId);
	}
	getExcludeValue(phaseOrResource) {
		const { phases = [] } = this.estimate;
		const dataType = phaseOrResource?.type === "project" ? "phase" : "resource";
		if (dataType === 'phase') {
			const phase = phases?.find(p => p.phaseId === phaseOrResource.id);
			return phase && phase.exclude === true;
		} else if (dataType === 'resource' && phases) {
			for (let p = 0; p < phases.length; p++) {
				for (let t = 0; t < phases[p].tasks?.length; t++) {
					for (let r = 0; r < phases[p].tasks[t].resources?.length; r++) {
						if (phases[p].tasks[t].resources[r].id === phaseOrResource.id) {
							return phases[p].tasks[t].resources[r].exclude === true || phases[p].exclude === true;
						}
					}
				}
			}
		}
		return false

	};

	dublicateTask(task, taskId) {
		const exclude = this.getExcludeValue(task);
		const clone = gantt.copy(task);
		const phaseId = gantt.getParent(taskId);
		const tasksChildrens = gantt.getChildren(phaseId);
		const addingTaskButtonId = tasksChildrens[tasksChildrens.length - 1];
		clone.id = gantt.uid();
		clone.exclude = exclude;
		gantt.addTask(clone, clone.parent, clone.$index);
		gantt.deleteTask(addingTaskButtonId);
		Helper.addButtonToProject(phaseId);
	}

	attachEventOnAddNewPhaseBtn() {
		const btnCss = "btn_add-phase";

		gantt.attachEvent("onEmptyClick", (e) => {
			const trg = e.target;

			if (trg && trg.classList.contains(btnCss)) {
				this.openGrid();
				Helper.addNewProject();
			}

			return true;
		});
	}

	openGrid() {

		if (this.isGridClosed) {
			this.currentScrollPosition = gantt.getScrollState();

			gantt.config.layout.rows[1].rows[0].css = "resource_addNewPhaseBtn";
			gantt.config.columns = this.gridConfig.opened;

			gantt.init("gantt");

			gantt.scrollTo(this.currentScrollPosition.x, this.currentScrollPosition.y);
			this.currentScrollPosition = null;
			this.isGridClosed = false;
		}
	}

	attachEventOnAddingTaskButton() {
		gantt.attachEvent("onTaskClick", (id, event) => {
			const element = event.target;
			const isItAddingButton = element.classList.contains("opening-button");
			const isItInternal = element.classList.contains("role-resourceBtn");
			const isItExternal = element.classList.contains("vendor-resourceBtn");

			if (isItAddingButton) {
				this.closeSelectedMenu("addingNewTaskButton-container", "active");
				element.parentElement.classList.toggle("active");
				return false;
			}
			else if (isItInternal) {

				const resourceType = element.dataset.resourceType;
				this.openGrid();
				this.addNewTask(id, resourceType);
				return false;
			}
			else if (isItExternal) {

				const resourceType = element.dataset.resourceType;
				this.openGrid();
				this.addNewTask(id, resourceType);
				return false;
			}

			return true;
		});

		this.closeOnWindow("addingNewTaskButton-container");
	}

	addNewTask(taskId, resourceType) {
		const phaseId = gantt.getParent(taskId);

		Helper.addNewTaskToProject(phaseId, resourceType);
		gantt.deleteTask(taskId);
		Helper.addButtonToProject(phaseId);
	}

	setLightboxStatus() {
		gantt.attachEvent("onTaskClick", (id, event) => {
			const element = event.target;
			const isItAddingButton = element.classList.contains("openingLightboxBtn") || element.classList.contains("hours");

			if (isItAddingButton) {
				gantt.showLightbox(id);
				document.querySelector('body').classList.add("gantt_resource_Drawer_open");
				return false;
			}
			return true;
		});
	}

	attachEventOnPhaseToggle() {
		// expand / collapse phase
		gantt.attachEvent("onTaskClick", (id, event) => {
			const element = event.target;
			const isItPhaseToggle = element.classList.contains("phase_toggle");
			if (isItPhaseToggle) {
				const phase = gantt.getTask(id);
				if (phase && phase.type === gantt.config.types.project) {
					if (phase.$open) {
						// gantt.updateTask(id);
						gantt.close(id);
						return false;
					}
					else {
						gantt.open(id);
						// gantt.updateTask(id);
						return false;
					}
				}
			}

			return true;
		});
	}

	attachEventOnGridToggle() {
		// expand / collapse phase
		gantt.attachEvent("onGridHeaderClick", (name, e) => {
			gantt.$container.querySelector(".btn_add-phase");
			const gridToggleCss = "grid_toggle";
			// set event here because we render btn inside gantt_grid_scale
			const trg = e.target;
			if (trg && trg.classList.contains(gridToggleCss)) {

				this.currentScrollPosition = gantt.getScrollState();

				this.isGridClosed = !this.isGridClosed;

				gantt.config.columns = this.isGridClosed
					? this.gridConfig.closed
					: this.gridConfig.opened;

				this.toggleContainerWithAddingPhaseButton();

				gantt.init("gantt");

				gantt.scrollTo(this.currentScrollPosition.x, this.currentScrollPosition.y);
				this.currentScrollPosition = null;
			}

			return true;
		});
	}

	toggleContainerWithAddingPhaseButton() {
		if (this.isGridClosed) {
			gantt.config.layout.rows[1].rows[0].css = "resource_addNewPhaseBtn closed_addNewPhaseBtn";
		} else {
			gantt.config.layout.rows[1].rows[0].css = "resource_addNewPhaseBtn";
		}
	}

	allowMoveOfPhasesOnly() {
		// only Phases(projects) can be dragged
		// block drag by input
		const isDragAllowed = (id, target) => {
			const isNameInput = target && target.classList
				? target.classList.contains("enter-resource")
				: false;
			const task = gantt.getTask(id);
			return task.type === gantt.config.types.project && !isNameInput;
		};

		gantt.attachEvent("onBeforeTaskMove", id => isDragAllowed(id));
		gantt.attachEvent("onBeforeRowDragMove", id => isDragAllowed(id));
		gantt.attachEvent("onRowDragStart", (id, target) => isDragAllowed(id, target));
	}

	attachEventOnModalWindow() {
		window.addEventListener("click", (event) => {
			const element = event.target;
			const isItModalCover = element.classList.contains("dhx_modal_cover");

			if (isItModalCover) {
				Helper.globalHideConfirmWindow();
			}
		});
	}
}
