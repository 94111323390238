import Helper from "../Helper";
import $ from 'jquery';
export default class ResourcesView {
	constructor() {
		this.isSummaryClosed = false;
	}

	init() {
		this.setConfig();
		this.setValueInCell();
		this.createResourceDataStore();
		this.attachEventOnSummaryToggle();
	}

	setConfig() {
		gantt.config.resource_store = "resource_store";
		gantt.config.resource_property = "costs";
		gantt.config.process_resource_assignments = false;
	}

	getIsSummaryClosed() {
		return this.isSummaryClosed;
	}

	getSummaryHeaderLayout() {
		const title = "<div class='summary_title'>Summary</div>";
		const toggle = "<div class='summary_toggle'></div>";
		return `<div class='summary_header'>${title}${toggle}</div>`;
	}

	get setLayout() {
		return {
			height: Helper.getScaleHeight() + Helper.getHeightOfSummary(),
			rows: [
				{
					height: Helper.getScaleHeight(),
					html: "<div class='btn_add-phase tooltip'></div>",
					css: "resource_addNewPhaseBtn"
				},
				{
					height: Helper.getHeightOfSummary(),
					config: {
						columns: [
							{
								name: "summary",
								label: this.getSummaryHeaderLayout(),
								template(resource) {
									return `<span class="summary-type-name">${resource.label}</span>`;
								}
							}
						]
					},
					cols: [
						{ view: "resourceGrid", group: "grids", scrollY: "resourceVScroll" },
						{ view: "resourceTimeline", scrollX: "scrollHor" },
						{ view: "scrollbar", id: "resourceVScroll" }
					]
				}
			]

		};
	}

	createResourceDataStore() {
		gantt.createDatastore({
			name: gantt.config.resource_store,
			type: "treeDatastore",
			initItem(item) {
				return item;
			}
		});
	}

	setValueInCell() {
		gantt.templates.resource_cell_value = (startDate, endDate, resource, tasks) => {
			const typeOfCalculation = resource.label.toLowerCase();
			const value = this.getAllocatedValue(typeOfCalculation, startDate, endDate, resource, tasks);
			return value;
		};
	}

	getAllocatedValue(typeOfCalculation, startDate, endDate, resource, tasks) {
		const dayInMs = 24 * 60 * 60 * 1000;
		const calculationMethods = {
			hours: task => this.calculateHours(task),
			cost: task => this.calculateCosts(task),
			price: task => this.calculatePrice(task)
		};

		let cellValue = 0;
		let currentDate = startDate.getTime();

		while (currentDate < endDate.getTime()) {
			if (gantt.isWorkTime(new Date(currentDate))) {
				tasks.forEach((task) => {
					cellValue += this.calculationValue(
						task,
						currentDate,
						calculationMethods,
						typeOfCalculation
					);
				});
			}
			currentDate += dayInMs;
		}

		if (!cellValue) return "";
		const convertCellValue = cellValue.toLocaleString("en", { style: "currency", currency: "USD" });
		const valueWithOutDots = convertCellValue.replace(/\.00/, "");

		return typeOfCalculation === "hours" ? Helper.numberRounding(cellValue) : valueWithOutDots;
	}

	calculationValue(task, currentDate, calculationMethods, typeOfCalculation) {
		const method = calculationMethods;
		const type = typeOfCalculation;
		const startDate = task.start_date.getTime();
		const endDate = currentDate && task.end_date.getTime();
		const isCorrectPosition = startDate <= currentDate && endDate > currentDate;
		const countingResult = isCorrectPosition ? method[type](task) : null;

		return countingResult;
	}

	calculateHours(task) {
		const hours = task.hours_per_day || 0;
		const qty = task.qty || 0;
		const costPerHour = task.cost_per_hour;
		if (!costPerHour) {
			return 0;
		}
		return hours * qty;
	}

	calculateCosts(task) {
		const hoursPerDay = task.hours_per_day || 0;
		const costPerHour = task.cost_per_hour || 0;
		const qty = task.qty || 0;
		const totalCost = hoursPerDay * costPerHour * qty;

		return totalCost;
	}

	calculatePrice(task) {
		const taskId = task.id;
		const phaseId = gantt.getParent(taskId);
		const phaseObject = gantt.getTask(phaseId);
		const hoursPerDay = task.hours_per_day || 0;
		const costPerHour = task.cost_per_hour || 0;
		const qty = task.qty || 0;
		const margin = phaseObject.margin ? (100 + phaseObject.margin) / 100 : 0;
		const billableRate = costPerHour * margin;
		const totalPrice = hoursPerDay * billableRate * qty;

		return totalPrice;
	}

	attachEventOnSummaryToggle() {
		// expand / collapse summary
		const summaryToggleCss = "summary_toggle";
		gantt.attachEvent("onEmptyClick", (e) => {
			const trg = e.target;

			if (trg && trg.classList.contains(summaryToggleCss)) {
				this.isSummaryClosed = !this.isSummaryClosed;
				if (this.isSummaryClosed) {
					delete gantt.config.layout.rows[1].rows[1].cols[0].scrollY;
					gantt.config.layout.rows[1].rows[1].height = Helper.getHeightOfClosedSummary();
					gantt.config.layout.rows[1].height = Helper.getHeightOfClosedSummary() + Helper.getScaleHeight();
					this.arrangeHeightCollapsed();
				}
				else {
					gantt.config.layout.rows[1].rows[1].cols[0].scrollY = "resourceVScroll";
					gantt.config.layout.rows[1].rows[1].height = Helper.getHeightOfSummary();
					gantt.config.layout.rows[1].height = Helper.getHeightOfSummary() + Helper.getScaleHeight();
					this.arrangeHeightExpaned();
				}
				gantt.init("gantt");
			}

			return true;
		});
	}

	collapse() {
		if (this.isSummaryClosed)
			return;
		this.isSummaryClosed = true;
		delete gantt.config.layout.rows[1].rows[1].cols[0].scrollY;
		gantt.config.layout.rows[1].rows[1].height = Helper.getHeightOfClosedSummary();
		gantt.config.layout.rows[1].height = Helper.getHeightOfClosedSummary() + Helper.getScaleHeight();
		gantt.init("gantt");
	}
	expand() {
		if (!this.isSummaryClosed)
			return;
		this.isSummaryClosed = false;
		gantt.config.layout.rows[1].rows[1].cols[0].scrollY = "resourceVScroll";
		gantt.config.layout.rows[1].rows[1].height = Helper.getHeightOfSummary();
		gantt.config.layout.rows[1].height = Helper.getHeightOfSummary() + Helper.getScaleHeight();
		gantt.init("gantt");
	}

	arrangeHeightCollapsed() {
		const ganttComp = $(".gantt-component");
		const currentHeight = ganttComp.height();
		ganttComp.height(currentHeight - Helper.getHeightOfSummary() + Helper.getHeightOfClosedSummary());

	}

	arrangeHeightExpaned() {
		const ganttComp = $(".gantt-component");
		const currentHeight = ganttComp.height();
		ganttComp.height(currentHeight + Helper.getHeightOfSummary() - Helper.getHeightOfClosedSummary());

	}
}
