import React, { useState } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { DefaultButton, OutlineButton } from "../UI/Buttons";
import InputField from "../UI/InputField";
import fire from '../../../config/firebase';

const useStyles = makeStyles(() => ({
  editRoot: {
    justifyContent: 'space-between',
    padding: '0'
  },
  iconButton: {
    paddingBottom: '10px'
  },
  alertIcon: {
    width: 36,
    height: 36,
    marginRight: '-5px'
  },
  editPaper: {
    maxWidth: '550px !important',
    paddingBottom: '20px',
    borderRadius: '5px !important',
    textAlign: 'center',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '10px',
  },
  editTitle: {
    padding: '0 !important',
    '& > *': {
      fontSize: 20,
      fontWeight: 'normal',
      lineHeight: 1.35,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: '#000000',
    }
  },
  editField: {
    marginLeft: '0px',
    width: '435px',
    marginRight: '0px',
  },
  sendButton: {
    marginTop: '10px',
    width: '80px',
  },
  cancelButton: {
    marginTop: '10px',
    width: '80px',
    marginLeft: '20px',
  },
  buttonsPosition: {
    display: "flex",
    justifyContent: 'flex-end',
    marginRight: '26px',
    marginTop: '10px'
  },
}));

export default function EditModal({ query, backupId, refreshData, closeDialog, close, open, defaultValue }) {

  const classes = useStyles();
  const [editedName, setEditedName] = useState(null);

  const handleClose = () => {
    closeDialog()
  }

  const handleEdit = (value) => {
    setEditedName(value)
  }

  const handleSave = () => {
    const estimateRef = fire.database().ref(query.companyId).child('backups').child(query.dataType)
      .child(query.estimateKey).child(backupId);
    estimateRef.update({ 'name': editedName });
    refreshData();
    closeDialog();
  }

  return (
    <React.Fragment>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        aria-labelledby="linkShare-dialog-title"
        aria-describedby="linkShare-dialog-description"
        classes={{ paper: classes.editPaper }}
        className="linkShare-overlay"
      >
        <Toolbar classes={{ root: classes.editRoot }}>
          <DialogTitle id="linkShare-dialog-title" className={`${classes.editTitle}`}>
            Edit  Estimate Backup Name
          </DialogTitle>
          <IconButton aria-label="delete" onClick={handleClose} className={`close-overlay ${classes.iconButton}`} style={{ paddingRight: '0px' }}>
            <CloseOutlinedIcon className={classes.alertIcon} htmlColor="#084d4f80" />
          </IconButton>
        </Toolbar>
        <div className="col">
          <div className={classes.editField}>
            <InputField
              label={"Name"}
              aria-labelledby="email"
              defaultValue={defaultValue}
              onChange={(e) => handleEdit(e.target.value)}
            />
          </div>
          <div className={classes.buttonsPosition}>
            <div className="row">
              <div className={classes.sendButton} >
                <DefaultButton
                  disabled={!editedName}
                  onClick={handleSave}
                  style={{ width: "80px" }}
                >
                  Save
                </DefaultButton>
              </div>
              <div className={classes.cancelButton} >
                <OutlineButton
                  onClick={handleClose}
                  style={{ width: "80px" }}
                >
                  Cancel
                </OutlineButton>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}