
import projectReducer from "./project";
import getEstimateReducer from "./estimate/getEstimate";
import getEstimateBackupReducer from "./estimateBackup/getEstimateBackup";
import getEstimatesReducer from "./estimates/getEstimates";
import getTemplatesReducer from "./templates/getTemplates";
import getCompanyReducer from "./company/getCompany";
import getTagsReducer from "./tags/getTags";
import getResourcesReducer from "./resources/getResources";
import getOrganizationsReducer from "./organizations/getOrganizations";
import getContactsReducer from "./contacts/getContacts";
import getUsersReducer from "./users/getUsers";
import getUserReducer from "./user/getUser"
import getPhasesReducer from "./phases/getPhases";
import getTasksReducer from "./tasks/getTasks";
import getDisciplinesReducer from "./disciplines/getDisciplines";
import getRefreshSignalReducer from "./refreshSignal/getRefreshSignal";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  getUser: getUserReducer,
  project: projectReducer,
  getEstimate: getEstimateReducer,
  getEstimateBackup: getEstimateBackupReducer,
  getEstimates: getEstimatesReducer,
  getTemplates: getTemplatesReducer,
  getTags: getTagsReducer,
  getCompany: getCompanyReducer,
  getResources: getResourcesReducer,
  getUsers: getUsersReducer,
  getOrganizations: getOrganizationsReducer,
  getContacts: getContactsReducer,
  getPhases: getPhasesReducer,
  getTasks: getTasksReducer,
  getDisciplines: getDisciplinesReducer,
  getRefreshSignal: getRefreshSignalReducer
});

export default rootReducer;