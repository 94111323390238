import React, { useEffect, useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import formatMoneyStrFriendly from '../../../libs/formatMoneyStrFriendly';
import { isDigit, getFloat } from '../../../libs/utils';

function ResourceEdit({ fieldKey = null, isFocused = false, vendorDrawer,
    resource, updateResource, max,
    field, prefix = "$", editable = false, updateInstantly = false,
    value = false, refresh = () => { } }) {

    const [fieldData, fieldDataSet] = useState(getFloat(resource[field]));
    const [temp, setTemp] = useState()
    useEffect(() => {
        if (value !== false) {
            const temp = Math.ceil(parseInt(fieldData))
            const tempNew = Math.ceil(parseInt(value))
            if (temp !== tempNew) {
                fieldDataSet(getFloat(value));
            }
        }
    }, [value]);

    const updateField = (input = '') => {
        let val = input;
        if (val.startsWith('$')) {
            val = val.substring(1, val.length);
            input = val;
        }
        val = val.replace(/,/g, '');
        let temp = NaN;
        try {
            temp = parseFloat(val);
        } catch {
            temp = NaN;
        }

        if (temp && temp >= 100000) {
            return;
        }
        if (isNaN(temp)) {
            temp = 0;
        }

        if (temp < 0) {
            temp = 0;
        }
        if (temp > max) {
            return;
            // val = max;
            // fieldDataSet(max);
        } else {
            if (input.length === 0) {
                fieldDataSet('0');
            } else if (input.length === 1) {
                fieldDataSet(input);
            } else {
                fieldDataSet(input.replace(/^0/, ''));
            }
        }

        let fieldToUpdate = field;
        let fieldToUpdateValue = temp; // this is used when user edits cost
        if (resource.cost && field === 'cost' && resource.type === 'Vendor') {
            const newHour = temp / parseFloat(resource.cost);
            fieldToUpdate = 'hours';
            fieldToUpdateValue = parseFloat(newHour);
        }
        const updatedData = { ...resource, }
        updatedData[fieldToUpdate] = fieldToUpdateValue;
        // updateResource(updatedData, resource, fieldKey);
        setTemp(updatedData)

        setTimeout(refresh, 100);
        if (updateInstantly) {
            updateResource(updatedData, resource, fieldKey, temp);
        }
    }
    const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });
    const calculatedFieldData = () => {
        if (editable === false) {
            return value;
        }
        const amountType = resource.amountType || resource.amounttype || 'rate';
        if (field === 'amount' && amountType === 'percentage') {
            const temp = resource.cost * ((resource.amount / 100) + 1);
            return temp.toFixed(2).replace('.00', '.');
        }
        return fieldData;
    }

    const onLoseFocus = () => {
        if (temp && !vendorDrawer) {
            //this.setState({ inputValue: event.target.value });
            updateResource(temp, resource, fieldKey);
            setTemp(null)
        }
    }
    const calculated = calculatedFieldData();
    return <ClickAwayListener onClickAway={onLoseFocus}>
        <Tooltip title={field === "hours" || calculated < 99999 ? '' : prefix + formatter.format(calculated)} placement="top-end">
            <input
                value={field === "hours" ? prefix + calculated
                    :
                    (formatMoneyStrFriendly({ number: value, numberFormat: false, beFriendly: true, maximumFractionDigits: 0 }))}

                autoFocus={isFocused}
                onBlur={onLoseFocus}
                onChange={(e) => {
                    if (isDigit(e.target.value) === false) {
                        return false;
                    }
                    if (editable) {
                        const val = e.target.value;
                        updateField(val);
                    }
                }}
                readOnly={editable === false}
                onKeyPress={(event) => {
                    if (event.charCode === 13 && temp && !vendorDrawer) {
                        //this.setState({ inputValue: event.target.value });
                        updateResource(temp, resource, fieldKey);
                        setTemp(null)
                    }
                }}
                style={{
                    marginLeft: 100,
                    //marginRight: 30,
                    textAlign: 'right', right: '0',
                    top: '6px', paddingLeft: '0px', color: 'rgba(0,0,0,0.5)',
                    fontWeight: '600', position: 'relative',
                    width: 54, border: 'none', fontSize: '14px'

                }}
            />
        </Tooltip>

    </ClickAwayListener >
}
export default ResourceEdit;